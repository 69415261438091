export default {
    site: {
        branding: {
            name: 'Mark McTamney',
            description: 'Entrepreneur and Developer.',
            icon: 'code',
            image: '/images/placeholder.jpg',
        },
        twitter: '@markmctamney',
        copyright: `© ${new Date().getFullYear()} Blog. Built with Next.js and Reflexjs.`,
        links: [
            {
                title: 'Home',
                href: '/',
            },
            {
                title: 'About',
                href: '/about',
            },
            {
                title: 'Styleguide',
                href: '/blog/styleguide',
            },
            {
                title: 'Contact',
                href: '/contact',
            },
        ],
    },
};
